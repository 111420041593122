a:link,
a:visited,
a:hover,
a:active {
  color: inherit;
}
.MuiLink-root a:link {
  text-decoration: inherit;
}
.main {
  margin-top: 2em;
}
